import { defineStore } from 'pinia'
import type {
  AnnualSavings,
  Case,
  Co2Emission,
  EconomicalQuality,
  EnergyConsumption,
  EnergyLabel,
  SocialQuality,
} from '@/types/case'

/**
 * Store for the qualities of a case: economical, social, and environmental
 */
export const useQualitiesStore = defineStore('qualities', () => {
  const { id } = storeToRefs(useCaseStore())
  const { selectedSuggestionsResult } = storeToRefs(
    useSuggestionAdjustmentStore()
  )
  const economicalQualities = ref<EconomicalQuality[]>()
  const socialQualities = ref<SocialQuality[]>()
  const annualSavings = ref<AnnualSavings | null>(null)

  // Environmental qualities are represented by the following three properties
  const energyConsumption = ref<EnergyConsumption | null>(null)
  const co2Emission = ref<Co2Emission | null>(null)
  const energyLabels = ref<EnergyLabel[] | null>(null)

  /**
   * Populate the store
   * @param currentCase The case to populate the store with
   */
  function populate(currentCase: Case) {
    economicalQualities.value = currentCase.property.economicalQualities
    socialQualities.value = currentCase.property.socialQualities
    annualSavings.value = currentCase.annualSavings
    energyConsumption.value = currentCase.energyConsumption
    co2Emission.value = currentCase.co2Emission
    energyLabels.value = currentCase.energyLabels
  }

  /**
   * Checks if the current case matches the selected suggestions result.
   *
   * Compares the ID of the selected suggestions result with the current ID,
   *
   * @returns {boolean} - Returns true if the IDs match, otherwise false.
   *
   */
  const isCurrentCase = () =>
    selectedSuggestionsResult.value?.id?.toLowerCase() ===
    id?.value?.toLowerCase()

  /**
   * If the current case is active, it returns the energy label from the calculation result.
   * Otherwise, it returns the energy label marked as 'Profitable' from the energy labels list.
   *
   * @returns {EnergyLabel | undefined} - The potential energy label or undefined if not found.
   */
  const potentialEnergyLabel = computed(() => {
    const potentialEnergyMark = energyLabels.value?.find(
      ({ energyLabelType }) => energyLabelType === 'All'
    )
    return isCurrentCase()
      ? selectedSuggestionsResult.value?.energyLabel
      : potentialEnergyMark
  })

  /**
   *
   * If the current case is active, it returns the CO2 emission savings from the calculation result.
   * Otherwise, it returns the overall CO2 emission savings.
   *
   * @returns {number | undefined} - The potential CO2 emission savings or undefined if not found.
   */
  const potentialCo2Emission = computed(() =>
    isCurrentCase()
      ? Math.round(selectedSuggestionsResult.value?.co2EmissionSavings || 0)
      : Math.round(co2Emission.value?.savingsPercentage || 0)
  )

  /**
   * If the current case is active, it returns the savings cost from the calculation result.
   * Otherwise, it returns the overall annual savings.
   *
   * @returns {number | undefined} - The potential annual savings or undefined if not found.
   */
  const potentialAnnualSavings = computed(() =>
    isCurrentCase()
      ? selectedSuggestionsResult.value?.savingsCost
      : annualSavings?.value?.all
  )

  return {
    economicalQualities,
    socialQualities,
    energyConsumption,
    co2Emission,
    energyLabels,
    annualSavings,
    populate,
    potentialEnergyLabel,
    potentialCo2Emission,
    potentialAnnualSavings,
  }
})
export type QualitiesStore = ReturnType<typeof useQualitiesStore>
